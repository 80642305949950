import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { searchAdminDataThunk } from "../../store/thunks/adminThunk";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import UserPreferencesModal from "./UserPreferencesModal";
import _ from "lodash";
import LoadingSpinner from "../LoadingSpinner";

const createSlug = (address) => {
  if (!address) return "";
  const { streetNumber, streetName, city, neighborhood } = address;
  return [streetNumber, streetName, city, neighborhood]
    .filter(Boolean)
    .join("-")
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, "");
};

const AdminSearch = ({ prefix, placeholder, onSelect }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchStatus, setSearchStatus] = useState("idle");
  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const debouncedSearch = useRef(
    _.debounce(async (input) => {
      if (!input.trim()) {
        setSearchResults([]);
        return;
      }

      try {
        setSearchStatus("loading");
        const results = await dispatch(
          searchAdminDataThunk({ query: `${prefix}${input}` }) // Prefix is automatically added
        ).unwrap();
        setSearchResults(results.data || []);
        setSearchStatus("succeeded");
      } catch (error) {
        console.error("Error searching:", error);
        setSearchStatus("failed");
        setSearchResults([]);
      }
    }, 300)
  ).current;

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setShowResults(true);
    debouncedSearch(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (item) => {
    if (prefix === ":listings:") {
      const slug = createSlug(item.address);
      const url = `/propertydetails/${item.mlsNumber}/${item.boardId}/${slug}`;
      window.open(url, "_blank");
    } else {
      onSelect(item);
    }

    setQuery("");
    setShowResults(false);
  };

  return (
    <div className="relative w-full max-w-lg mx-auto" ref={searchRef}>
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={handleChange}
          placeholder={placeholder}
          className="w-full px-4 py-2 border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
        <MagnifyingGlassIcon className="absolute right-3 top-2.5 w-5 h-5 text-gray-500" />
      </div>

      {showResults && (
        <div className="absolute left-0 right-0 bg-white border border-gray-300 mt-1 shadow-lg max-h-60 overflow-auto z-50">
          {searchStatus === "loading" ? (
            <div className="p-3 text-center text-sm text-gray-500">
              <LoadingSpinner title={"Searching..."} />
            </div>
          ) : searchResults.length === 0 ? (
            <div className="p-3 text-center text-gray-500">
              No results found.
            </div>
          ) : (
            searchResults.map((item) => (
              <div
                key={item._id}
                className="p-3 hover:bg-gray-100 cursor-pointer flex items-center"
                onClick={() => handleSelect(item)}>
                <div className="flex-grow">
                  {prefix === ":user:" ? (
                    <>
                      <p className="font-semibold text-gray-900">
                        {item.firstName} {item.lastName}
                      </p>
                      <p className="text-sm text-gray-600">{item.username}</p>
                    </>
                  ) : (
                    <>
                      <p className="font-semibold text-gray-900">
                        {item.address.unitNumber
                          ? `${item.address.unitNumber} - `
                          : ""}
                        {item.address.streetNumber} {item.address.streetName}{" "}
                        {item.address.streetSuffix}, {item.address.city}
                      </p>
                    </>
                  )}
                </div>
                <span className="text-xs bg-gray-200 px-2 py-1 rounded-md">
                  {prefix === ":user:" ? item.userType : item.mlsNumber}
                </span>
              </div>
            ))
          )}
        </div>
      )}

      {selectedUser && (
        <UserPreferencesModal
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      )}
    </div>
  );
};

export default AdminSearch;
