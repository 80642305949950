import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchPersonalizedPropertiesThunk,
  fullTextSearchThunk,
} from "../store/thunks/repliersThunk";
import { logoutThunk } from "../store/thunks/authThunk";
import { sendUserActivityLog } from "../store/thunks/userActivityLogThunk";
import {
  clearSearchResults,
  setSearchTerm as setGlobalSearchTerm,
} from "../store/slices/repliersSlice";

import { motion } from "framer-motion"; // Import motion for animations
import PropertyCarousel from "../components/properties/PropertyCarousel";
import FindYourDream from "../components/FindYourDream";
import LoadingSpinner from "../components/LoadingSpinner";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const { nlpSearchResults, nlpStatus, nlpSearchSummary } = useSelector(
    (state) => state.repliersNlp
  );

  const {
    fullTextSearchResults,
    fullTextSearchStatus,
    fullTextSearchError,
    currentSearchTerm,
  } = useSelector((state) => state.repliers);

  const [loading, setLoading] = useState(true);

  // Fetch dashboard data on mount
  useEffect(() => {
    // Short timeout to show initial loading state
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  // Function to handle search from FindYourDream component
  const handleSetSearchTerm = (term) => {
    if (term && term.trim()) {
      dispatch(setGlobalSearchTerm(term));
      dispatch(
        fullTextSearchThunk({
          search: term,
          pageNum: 1,
          resultsPerPage: 20,
        })
      );
    } else {
      dispatch(clearSearchResults());
    }
  };

  // Check if we have any search results to display
  const hasSearchResults =
    currentSearchTerm &&
    currentSearchTerm.trim() !== "" &&
    fullTextSearchStatus === "succeeded" &&
    fullTextSearchResults?.listings?.length > 0;

  // Handle search pagination
  const handleSearchPagination = (page) => {
    if (currentSearchTerm && currentSearchTerm.trim()) {
      dispatch(
        fullTextSearchThunk({
          search: currentSearchTerm,
          pageNum: page,
          resultsPerPage: 20,
        })
      );

      dispatch(
        sendUserActivityLog({
          eventType: "DASHBOARD_SEARCH_PAGINATION",
          metadata: {
            query: currentSearchTerm,
            page,
          },
        })
      );
    } else {
      console.warn(
        "DashboardPage: Pagination requested but no search term is set"
      );
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <main className="container mx-auto py-8">
        <FindYourDream setSearchTerm={handleSetSearchTerm} />

        {loading ? (
          <div className="text-center pt-10">
            <div className="loader"></div>
            <LoadingSpinner title={"Curating your dashboard"} />
          </div>
        ) : (
          <>
            {/* Search Results */}
            {hasSearchResults && (
              <PropertyCarousel
                title={`Results for "${currentSearchTerm}"`}
                description={`Found ${
                  fullTextSearchResults.count || 0
                } properties matching your search`}
                properties={fullTextSearchResults.listings}
                currentPage={fullTextSearchResults.currentPage || 1}
                totalPages={fullTextSearchResults.numPages || 1}
                pageSize={20}
                onPageChange={handleSearchPagination}
              />
            )}

            {/* Also show search status if we're searching but don't have results yet */}
            {currentSearchTerm && fullTextSearchStatus === "loading" && (
              <PropertyCarousel
                title={`Searching for "${currentSearchTerm}"`}
                placeholderText={`Searching for "${currentSearchTerm}"...`}
                pageSize={20}
              />
            )}

            {/* Show a message if search completed but no results */}
            {currentSearchTerm &&
              fullTextSearchStatus === "succeeded" &&
              (!fullTextSearchResults?.listings ||
                fullTextSearchResults.listings.length === 0) && (
                <div className="text-center p-8 bg-white rounded-lg shadow-md my-4">
                  <h3 className="text-xl font-semibold text-gray-800">
                    No results found
                  </h3>
                  <p className="text-gray-600 mt-2">
                    No properties match your search for "{currentSearchTerm}".
                    Try a different search term.
                  </p>
                </div>
              )}

            {/* NLP Listings */}
            {nlpStatus === "loading" && (
              <PropertyCarousel
                title="NLP Results"
                placeholderText="Processing your natural language query..."
                pageSize={10}
              />
            )}

            {nlpStatus === "succeeded" &&
              nlpSearchResults?.listings?.length > 0 && (
                <PropertyCarousel
                  title={`Natural Language Results`}
                  properties={nlpSearchResults.listings}
                  description={nlpSearchSummary}
                  pageSize={20}
                />
              )}

            {/* Personalized Recommendations */}
            <PropertyCarousel
              title="Personalized Results"
              description="These are personalized recommendations based on your onboarding preferences."
              pageSize={20}
              fetchType="personalized"
            />

            <PropertyCarousel
              title="Recently Sold"
              description="Properties sold in the last 60 days that match your preferences."
              pageSize={20}
              fetchType="personalizedSold"
            />

            <PropertyCarousel
              title="Recently Listed"
              description="Properties listed in the last 60 days that match your preferences."
              pageSize={20}
              fetchType="personalizedList"
            />

            {/* General Recommendations */}
            {/* <PropertyCarousel
              title="General Recommendations"
              description="These are general recommendations."
              pageSize={20}
              fetchType="general"
            /> */}
          </>
        )}
      </main>
    </div>
  );
};

export default DashboardPage;
